/* eslint-disable no-nested-ternary */
import { styled } from "@material-ui/core";
import React from "react";
import { Colour, color } from "../theme/Design";
import { theme } from "../theme/Theme";

const Stepper = {
  display: "grid",
  gridGap: 8,
  fontSize: theme.typography.fontSize,
};

export const StepperWithThreeSteps = styled("div")(() => ({
  ...Stepper,
  gridTemplateColumns: "1fr 1fr 1fr",
  marginBottom: "64px",
}));

export const StepperWithTwoSteps = styled("div")(() => ({
  ...Stepper,
  gridTemplateColumns: "1fr 1fr",
}));

interface IStep {
  isActive: boolean;
  isComplete: boolean;
  label: string;
  themeType?: "light" | "dark";
}

export const Step = styled(({ isComplete, label, isActive, themeType = "dark", ...HTMLProps }: IStep) => (
  <div {...HTMLProps}>
    {isComplete && <TickIcon className={themeType === "light" ? "icon-dark" : "icon-green"} />}
    {label}
    <hr />
  </div>
))({
  color: (props: IStep) =>
    props.isActive || props.isComplete
      ? props.themeType === "light"
        ? theme.palette.grey[800]
        : Colour.mint20
      : "currentColor",
  textAlign: "center",
  fontWeight: (props: IStep) => (props.isActive || props.isComplete ? 600 : 400),
  "& hr": {
    border: 0,
    height: 3,
    background: (props: IStep) =>
      props.isActive || props.isComplete
        ? props.themeType === "light"
          ? theme.palette.grey[800]
          : theme.color.primary.main
        : color.lightGray,
    display: "block",
  },
});

const TickIcon = styled(({ ...other }) => (
  <svg {...other} width="15" height="15" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.171 1.32764L5.16417 16.6706L0.828125 12.3345" stroke={Colour.mint20} strokeWidth="2" />
  </svg>
))({
  marginRight: 10,
  marginTop: 2,
  width: 16,
  verticalAlign: "top",
  "&.icon-dark path": {
    stroke: theme.palette.grey[800],
  },
  "&.icon-green path": {
    stroke: Colour.mint20,
  },
});
