import React, { useEffect, useState } from "react";
import { IUserProfile } from "@boulevard1/mystake-api-sdk";
import { authService, userService } from "../util";

interface AppContextInterface {
  currentUser: IUserProfile | null;
  updateUser: () => Promise<void>;
  invalidateCurrentUser: () => void;
  loader: boolean;
  setIsInvestor: (e: boolean) => void;
  getUpdatedUser: () => Promise<IUserProfile | null>;
}

export const AuthContext = React.createContext<AppContextInterface>({
  currentUser: null,
  updateUser: async (): Promise<void> => {},
  invalidateCurrentUser: () => {},
  loader: true,
  setIsInvestor: () => {},
  getUpdatedUser: async (): Promise<IUserProfile | null> => null,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IUserProfile | null>(null);
  const [loader, showLoader] = useState(true);
  const [isInvestor, setIsInvestor] = useState(false);

  React.useEffect(() => {
    if (currentUser) {
      setCurrentUser({
        ...currentUser,
        isInvestor,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvestor, setCurrentUser]);

  const updateUser = async (): Promise<void> => {
    try {
      if (!currentUser) {
        await setUser();
      } else {
        const userProfile = await userService.getUser();
        userProfile.isInvestor = isInvestor;
        setCurrentUser(userProfile);
      }
    } catch (err) {
      setCurrentUser(null);
    }
    showLoader(false);
  };

  const getUpdatedUser = async (): Promise<IUserProfile | null> => {
    try {
      const userProfile = await userService.getUser();
      userProfile.isInvestor = isInvestor;
      return userProfile;
    } catch (err) {
      setCurrentUser(null);
      return null;
    }
  };

  const setUser = async (): Promise<void> => {
    try {
      const userProfile = await userService.getUser();
      const isAdmin = !!userProfile.roles.includes("ADMIN");
      setIsInvestor(!isAdmin);
      userProfile.isInvestor = !isAdmin;
      setCurrentUser(userProfile);
    } catch (err) {
      setCurrentUser(null);
    }
    showLoader(false);
  };

  const invalidateCurrentUser = async () => {
    setCurrentUser(null);
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await authService.currentUser();
      if (user) {
        setUser();
      } else {
        setCurrentUser(null);
        showLoader(false);
      }
    };
    fetchCurrentUser();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        updateUser,
        invalidateCurrentUser,
        loader,
        setIsInvestor,
        getUpdatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthContext = () => React.useContext(AuthContext);
