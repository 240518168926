import { Theme } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { styled, withStyles } from "@material-ui/styles";
import React, { ElementType } from "react";
import { color, dashboardColor } from "../theme/Design";
import { theme } from "../theme/Theme";

interface MuiButtonProps extends ButtonProps {
  themeType?: "dark" | "light";
  loader?: boolean;
  fill?: boolean;
  style?: React.CSSProperties;
  component?: ElementType;
}

export const MuiButton = styled(({ loader, themeType, fill, style, ...other }: MuiButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    style={
      !fill
        ? { width: "fit-content", minWidth: 110, height: 36, paddingRight: 15, paddingLeft: 15, ...style }
        : { ...style }
    }
    {...other}
  >
    {!loader && other.children}
    {loader && <CircularProgress size={15} />}
  </Button>
))({
  whiteSpace: "nowrap",
  minHeight: 36,
  fontWeight: 600,
  fontSize: 14,
  textTransform: "inherit",
  boxShadow: (props: MuiButtonProps) =>
    theme.palette.background.default === dashboardColor.bg || props.themeType === "light"
      ? "0px 4px 4px rgba(0, 0, 0, 0.25) !important"
      : "none !important",
  letterSpacing: "0.16px",
  "&.Mui-disabled": {
    boxShadow: "none !important",
  },
  "&.MuiCircularProgress-colorPrimary": {
    color: (props: MuiButtonProps) => (props.themeType === "light" ? theme.palette.grey[800] : color.green),
  },
  "&.MuiButton-containedSecondary": {
    background: theme.palette.grey[800],
    color: color.white,
  },
  "&.MuiButton-containedPrimary": {
    background: theme.color.primary.main,
    "&:hover": {
      background: theme.palette.primary.light,
    },
    "&.Mui-disabled": {
      background: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[300] : theme.palette.primary.light,
      color: (props: MuiButtonProps) => (props.themeType === "light" ? color.white : theme.palette.grey[500]),
      "& .MuiCircularProgress-colorPrimary": {
        color: theme.palette.grey[800],
      },
    },
  },
  "&.MuiButton-outlinedPrimary": {
    border: "1px solid",
    borderColor: (props: MuiButtonProps) => (props.themeType === "light" ? color.info : theme.palette.primary.main),
    color: (props: MuiButtonProps) => (props.themeType === "light" ? theme.palette.grey[800] : color.white),
    "&:hover": {
      borderColor: theme.palette.primary.light,
      background: theme.palette.primary.light,
      color: theme.palette.grey[800],
    },
    "&.Mui-disabled": {
      borderColor: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[500] : theme.color.primary.main,
      color: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[500] : theme.color.primary.main,
    },
  },
  "& span, &.MuiButton-endIcon.MuiButton-iconSizeMedium": { margin: 0 },
  "&.MuiButton-textPrimary, &.MuiButton-text": {
    color: (props: MuiButtonProps) => (props.themeType === "light" ? theme.palette.grey[800] : color.white),
    border: 0,
    "&:hover": {
      background: theme.palette.primary.light,
      color: (props: MuiButtonProps) => (props.themeType === "light" ? theme.palette.grey[800] : color.white),
    },
    "&.Mui-disabled": {
      color: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[500] : theme.color.disabledColor,
    },
    "&:not(:hover), &:not(.Mui-disabled)": {
      boxShadow: "none !important",
    },
  },
  "&.MuiButton-textPrimary": {
    color: (props: MuiButtonProps) =>
      props.themeType === "light" ? theme.color.primary.dark : theme.palette.primary.main,
  },
  background: (props: MuiButtonProps) => (props.variant && props.variant !== "text" ? "" : "transparent"),
});

export const BtnGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  "& button, & .MuiButton-root": {
    marginLeft: "20px",
  },
  "&.align-left": {
    justifyContent: "flex-start",
    "& button, & .MuiButton-root": {
      marginLeft: "0px",
      marginRight: 20,
    },
  },
  "&.align-center": {
    justifyContent: "center",
    "& button + button, & .MuiButton-root + .MuiButton-root": {
      marginLeft: "20px",
    },
  },
});

export const IconButton = withStyles((muiTheme: Theme) => ({
  root: {
    color: color.gray,
    width: 26,
    height: 26,
    padding: 0,
    borderRadius: 4,
    "&:hover": {
      background: muiTheme.palette.primary.light,
      borderColor: muiTheme.palette.primary.light,
      color: color.black,
    },
  },
  colorSecondary: {
    borderColor: "transparent",
    background: muiTheme.palette.grey[800],
    color: muiTheme.palette.common.white,
  },
  colorPrimary: {
    borderColor: "transparent",
    background: muiTheme.palette.primary.light,
    color: muiTheme.palette.grey[800],
  },
}))((props: IconButtonProps) => <MuiIconButton {...props} />);
