import React from "react";
import { IInvestorsListData } from "@boulevard1/mystake-common-sdk";
import { makeStyles } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  SortIcon,
  SuccessIcon,
} from "../../uiComponents";
import { personNameToString } from "../../utility/formatter";
import { Skeleton } from "../../commons/Styled";
// eslint-disable-next-line import/no-unresolved, import/extensions
import { Order } from "../../../types";

interface HeadCell {
  id: string;
  numeric: boolean;
  label: string;
  align?: "left" | "right";
}

export enum InvestorsTableColumns {
  NAME = "legalEntityName",
  CONTACT_NAME = "contact_name",
  SHARES = "sharesValue",
  OPTIONS = "optionsCount",
  NOTES = "notesValue",
}

interface IInvestorsTableData {
  investorsList: IInvestorsListData["investors"] | undefined;
  sortOrder: Order;
  sortBy: InvestorsTableColumns;
  loading: boolean;
  setSortOrder: (e: Order) => void;
  setSortBy: (e: InvestorsTableColumns) => void;
}

const headCells: HeadCell[] = [
  { id: InvestorsTableColumns.NAME, numeric: false, label: "Investor name" },
  { id: InvestorsTableColumns.CONTACT_NAME, numeric: false, label: "Contact name" },
  { id: InvestorsTableColumns.SHARES, numeric: true, label: "Shares", align: "right" },
  { id: InvestorsTableColumns.OPTIONS, numeric: true, label: "Options", align: "right" },
  { id: InvestorsTableColumns.NOTES, numeric: true, label: "Notes", align: "right" },
];

const useStyles = makeStyles({
  table: {
    paddingTop: 16,
  },
});

export const InvestorsTable: React.FC<IInvestorsTableData> = ({
  investorsList,
  sortBy,
  setSortBy,
  sortOrder,
  loading,
  setSortOrder,
}) => {
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: InvestorsTableColumns) => {
    const isAsc = sortBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead style={{ marginTop: 16 }}>
        <TableRow>
          {headCells.map((headCell: HeadCell) => (
            <TableCell
              key={headCell.id}
              align={headCell?.align || "left"}
              sortDirection={sortBy === headCell.id ? sortOrder : false}
            >
              <TableSortLabel
                active={sortBy === headCell.id}
                direction={sortBy === headCell.id ? sortOrder : "asc"}
                onClick={(e) => handleRequestSort(e, headCell.id as InvestorsTableColumns)}
                IconComponent={SortIcon}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading &&
          investorsList &&
          investorsList.length > 0 &&
          investorsList.map((row: IInvestorsListData["investors"][0]) => {
            const key = row._id;

            const contactName = personNameToString(
              row.contact?.firstName,
              row.contact?.secondName,
              row.contact?.thirdName,
              row.contact?.familyName,
            );

            return (
              <TableRow key={key}>
                <TableCell align="left">{row.legalEntityName}</TableCell>
                <TableCell align="left">{contactName}</TableCell>
                <TableCell align="right">{row.sharesValue ? <SuccessIcon /> : "-"}</TableCell>
                <TableCell align="right">{row.optionsCount ? <SuccessIcon /> : "-"}</TableCell>
                <TableCell align="right">{row.notesValue ? <SuccessIcon /> : "-"}</TableCell>
              </TableRow>
            );
          })}
        {!loading && (!investorsList || investorsList.length === 0) && (
          <TableRow>
            <TableCell height="100" colSpan={5} align="center" style={{ textAlign: "center" }}>
              No results
            </TableCell>
          </TableRow>
        )}
        {loading && (
          <TableRow>
            <TableCell height="100" colSpan={1} align="center">
              <Skeleton />
            </TableCell>
            <TableCell height="100" colSpan={1} align="center">
              <Skeleton />
            </TableCell>
            <TableCell height="100" colSpan={1} align="center">
              <Skeleton />
            </TableCell>
            <TableCell height="100" colSpan={1} align="center">
              <Skeleton />
            </TableCell>
            <TableCell height="100" colSpan={1} align="center">
              <Skeleton />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
