import React from "react";
import { useLocation } from "react-router-dom";
import {
  IFundraiseConfig,
  IShareCertificateConfig,
  INotesCertificateConfig,
  ILiquidiseConfig,
} from "@boulevard1/mystake-common-sdk";
import { useSnackbar } from "notistack";
import { defaultNotesCertificateConfig, defaultShareCertificateConfig, Tier } from "../../constants/constants";
import { companyService } from "../../util";
import { useAuthContext } from "../../auth/AuthProvider";
import { errorMessage } from "../commons/errorHandler";

interface CompanyContextType {
  companyId: string;
  companyName: string;
  setCompanyName: (e: string) => void;
  acn: string;
  setAcn: (e: string) => void;
  entityType: string;
  setEntityType: (e: string) => void;
  trusteeType: string;
  setTrusteeType: (e: string) => void;
  isAsicConnectionActivated: boolean;
  setAsicConnectionActivated: (e: boolean) => void;
  isLoadingCompanyInfo: boolean;
  setIsLoadingCompanyInfo: (e: boolean) => void;
  selectedSubscriptionPlan: string;
  setSelectedSubscriptionPlan: (planName: string) => void;
  selectedTier: Tier;
  setSelectedTier: (tierName: Tier) => void;
  displayName: string;
  setDisplayName: (name: string) => void;
  displayLogo: string;
  setDisplayLogo: (url: string) => void;
  refreshCompanyData: (newCompanyId?: string) => void;
  companyType: string;
  activeShareholders: number;
  setActiveShareholders: (count: number) => void;
  features: { [key: string]: boolean };
  dateOfRegistration: Date | null;
  isAgentAuthorized: boolean;
  fundraiseConfig: IFundraiseConfig | undefined;
  liquidiseConfig?: ILiquidiseConfig;
  shareCertificateConfig?: IShareCertificateConfig;
  setShareCertificateConfig: (data: IShareCertificateConfig | undefined) => void;
  notesCertificateConfig?: INotesCertificateConfig;
  setNotesCertificateConfig: (data: INotesCertificateConfig | undefined) => void;
}

export const CompanyContext = React.createContext<CompanyContextType>({
  companyId: "",
  companyName: "",
  setCompanyName: () => {},
  acn: "",
  setAcn: () => {},
  entityType: "",
  setEntityType: () => {},
  trusteeType: "",
  setTrusteeType: () => {},
  isAsicConnectionActivated: false,
  setAsicConnectionActivated: () => {},
  isLoadingCompanyInfo: false,
  setIsLoadingCompanyInfo: () => {},
  selectedSubscriptionPlan: "",
  setSelectedSubscriptionPlan: () => {},
  selectedTier: "tier4",
  setSelectedTier: () => {},
  displayName: "",
  setDisplayName: () => {},
  displayLogo: "",
  setDisplayLogo: () => {},
  refreshCompanyData: (newCompanyId?: string) => {},
  companyType: "",
  activeShareholders: 0,
  setActiveShareholders: () => {},
  features: {},
  dateOfRegistration: null,
  isAgentAuthorized: false,
  fundraiseConfig: undefined,
  liquidiseConfig: undefined,
  shareCertificateConfig: defaultShareCertificateConfig,
  setShareCertificateConfig: () => {},
  notesCertificateConfig: defaultNotesCertificateConfig,
  setNotesCertificateConfig: () => {},
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCompanyContext = () => React.useContext(CompanyContext);

export const CompanyContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [companyName, setCompanyName] = React.useState("");
  const [acn, setAcn] = React.useState("");
  const [entityType, setEntityType] = React.useState<string>("");
  const [trusteeType, setTrusteeType] = React.useState<string>("");
  const [displayName, setDisplayName] = React.useState<string>("");
  const [displayLogo, setDisplayLogo] = React.useState<string>("");
  const [isAsicConnectionActivated, setAsicConnectionActivated] = React.useState<boolean>(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = React.useState<string>("");
  const [selectedTier, setSelectedTier] = React.useState<Tier>("tier4");
  const [activeShareholders, setActiveShareholders] = React.useState<number>(0);
  const [features, setFeatures] = React.useState<{ [key: string]: boolean }>({});
  const [companyType, setCompanyType] = React.useState("");
  const [dateOfRegistration, setDateOfRegistration] = React.useState<Date | null>(null);
  const [isAgentAuthorized, setIsAgentAuthorized] = React.useState(false);
  const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = React.useState(false);
  const [fundraiseConfig, setFundraiseConfig] = React.useState<IFundraiseConfig | undefined>(undefined);
  const [liquidiseConfig, setLiquidiseConfig] = React.useState<ILiquidiseConfig | undefined>(undefined);
  const [shareCertificateConfig, setShareCertificateConfig] = React.useState<IShareCertificateConfig | undefined>(
    undefined,
  );
  const [notesCertificateConfig, setNotesCertificateConfig] = React.useState<INotesCertificateConfig | undefined>(
    undefined,
  );

  const { enqueueSnackbar } = useSnackbar();

  const { currentUser } = useAuthContext();

  const matchCompanyId = location.pathname.match(/companies-.*?(?=(?:\/))/);
  let companyId = matchCompanyId ? matchCompanyId[0] : "";

  const getCompanyInfo = React.useCallback(async () => {
    setIsLoadingCompanyInfo(true);
    if (companyId) {
      try {
        const result = await companyService.getCompanyInfo(companyId);
        const company = result.payload;
        setCompanyName(company.name);
        setAcn(company.acn);
        setDisplayLogo(company.displayLogo);
        setDisplayName(company.displayName);
        setAsicConnectionActivated(company.isAsicConnectionActivated);
        setSelectedSubscriptionPlan(company.stripeData.selectedSubscriptionPlan);
        setSelectedTier(
          (company.stripeData.selectedTier ??
            (company.stripeData.selectedSubscriptionPlan === "plan1" ? "tier1" : "tier4")) as Tier,
        );
        setCompanyType(company.companyType);
        setActiveShareholders(company.activeShareholders ?? 0);
        setFeatures(company.features || {});
        setDateOfRegistration(company.dateOfRegistration || null);
        setIsAgentAuthorized(company.isAgentAuthorized || false);
        setFundraiseConfig(company.fundraiseConfig ?? undefined);
        setLiquidiseConfig(company.liquidiseConfig ?? undefined);
        setShareCertificateConfig(company.shareCertificateConfig ?? undefined);
        setNotesCertificateConfig(company.notesCertificateConfig ?? undefined);
      } catch (error) {
        if (currentUser) {
          enqueueSnackbar(errorMessage(error, "An error occurred retrieving your company information."), {
            variant: "error",
          });
        }
      }
    }
    setIsLoadingCompanyInfo(false);
  }, [companyId, currentUser, enqueueSnackbar]);

  React.useEffect(() => {
    getCompanyInfo();
  }, [getCompanyInfo]);

  const refreshCompanyData = (newCompanyId?: string) => {
    companyId = newCompanyId ?? companyId;
    getCompanyInfo();
  };

  return (
    <CompanyContext.Provider
      value={{
        companyId,
        companyName,
        setCompanyName,
        acn,
        setAcn,
        entityType,
        setEntityType,
        trusteeType,
        setTrusteeType,
        isAsicConnectionActivated,
        setAsicConnectionActivated,
        selectedSubscriptionPlan,
        setSelectedSubscriptionPlan,
        displayName,
        setDisplayName,
        displayLogo,
        setDisplayLogo,
        refreshCompanyData,
        companyType,
        selectedTier,
        setSelectedTier,
        activeShareholders,
        setActiveShareholders,
        features,
        dateOfRegistration,
        isAgentAuthorized,
        fundraiseConfig,
        liquidiseConfig,
        shareCertificateConfig,
        setShareCertificateConfig,
        notesCertificateConfig,
        setNotesCertificateConfig,
        isLoadingCompanyInfo,
        setIsLoadingCompanyInfo,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
