import { ICompany, IUserEmail, IUserProfile } from "@boulevard1/mystake-api-sdk";
import { CompanyListItemStatus } from "@boulevard1/mystake-common-sdk";
import { Logger } from "@boulevard1/mystake-logger-sdk";
import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";
import { PageRoutes, UserStatus } from "../constants/constants";
import { companyService, userService } from "../util";

export const logger = new Logger("console", { level: "error" });

interface IRedirectLookup {
  [key: string]: {
    url: string;
    relatedURLs: string[];
  };
}

const redirectLookup: IRedirectLookup = {
  emailNotVerified: {
    url: "/verify-email",
    relatedURLs: [],
  },
  phoneNotVerified: {
    url: "/email-verified",
    relatedURLs: ["verify-phone"],
  },
  verifyIDPending: {
    url: "/verify-id",
    relatedURLs: ["email-verified", "verify-email/:invalid", "invalid-token"],
  },
  greenIDPending: {
    url: "/green-id",
    relatedURLs: ["green-id-pending", "green-id"],
  },
};

const getUserStatus = (user: IUserProfile, pathName: string) => {
  const isEmailVerified = user.emails?.find((email: IUserEmail) => email.isPrimary)?.isVerified || false;

  if (!isEmailVerified) {
    return "emailNotVerified";
  }

  // If the phone field contains an empty array, the code below sets isPhoneVerified to true because some users
  // do not have to provide a phone number as part of the onboarding flow e.g. investors, and admins who have
  // been invited to join an existing company account
  const isPhoneVerified =
    (user && (!user.phones.length || user.phones.find((phone) => phone.isPrimary)?.isVerified)) || false;

  if (!isPhoneVerified) {
    return "phoneNotVerified";
  }

  if (
    ((!user.birthDetails?.dob || !user.kycDetails?.address) && !user.isInvestor) ||
    ((!user.birthDetails?.dob || !user.kycDetails?.address) &&
      (pathName.substring(0, 18) === "investor/verify-id" || pathName.substring(0, 18) === "wallet/verify-id") &&
      user.isInvestor)
  ) {
    return "verifyIDPending";
  }

  if (
    (!user?.kycDetails.isKYCDone && !user.isInvestor) ||
    ((pathName.substring(0, 17) === "investor/green-id" ||
      pathName.substring(0, 17) === "wallet/green-id" ||
      pathName.substring(0, 18) === "investor/verify-id" ||
      pathName.substring(0, 18) === "wallet/verify-id") &&
      user.isInvestor)
  ) {
    return "greenIDPending";
  }

  return "done";
};

export interface MatchParams {
  companyId?: string;
}

export const CheckOnboardingFlow: React.FC = () => {
  logger.log("info", "CheckOnboardingFlow entry");
  const { currentUser, getUpdatedUser } = React.useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const match = useRouteMatch<MatchParams>("/company/:companyId");
  const matchAddCompany = useRouteMatch<MatchParams>("/add-company/:companyId");
  const matchNomineeCompany = useRouteMatch<MatchParams>("/nominee/authorise-mystake-final-step/:companyId");
  const matchInvestorCompany = useRouteMatch<MatchParams>("/investor/company/:companyId");
  const companyIdParam =
    match?.params?.companyId ||
    matchAddCompany?.params?.companyId ||
    matchNomineeCompany?.params?.companyId ||
    matchInvestorCompany?.params?.companyId;

  const redirectRoute = React.useCallback(
    async (pathname: string) => {
      logger.log("info", "CheckOnboardingFlow entering redirectRoute");
      try {
        const currentUserUpdatedInfo = await getUpdatedUser();

        if (!currentUserUpdatedInfo) {
          return;
        }

        const pathName = pathname.replace(/^\/+/g, "");
        const userStatus = getUserStatus(currentUserUpdatedInfo, pathName);

        const doNotRedirectUrls = [
          "user/confirm-email-update",
          "user/cancel-email-update",
          "investor/create-account/",
          "admin/create-account/",
          "nominee/create-account/",
        ];

        if (doNotRedirectUrls.includes(pathName)) {
          return;
        }

        const commonURLs = ["wallet", "account", "add-company", "switch-company", "nominee-account-created"];

        const verifyIdURLs = [
          "verify-id",
          "admin/verify-id",
          "wallet/verify-id",
          "email-verified",
          "verify-email",
          "invalid-token",
          "invitation-revoked",
          "green-id",
          "green-id-pending",
          "admin/green-id",
          "wallet/green-id",
        ];

        const isEmailVerified =
          currentUserUpdatedInfo.emails?.find((email: IUserEmail) => email.isPrimary)?.isVerified || false;
        const isPhoneVerified =
          (currentUserUpdatedInfo &&
            (!currentUserUpdatedInfo.phones.length ||
              currentUserUpdatedInfo.phones.find((phone) => phone.isPrimary)?.isVerified)) ||
          false;

        const verificationRedirect = (prefix = "") => {
          const { url, relatedURLs } = redirectLookup[userStatus];

          const fullURL = prefix ? `/${prefix}${url}` : url;

          const additionalRelatedURLs = prefix ? relatedURLs.map((relatedURL) => `${prefix}/${relatedURL}`) : [];

          const allURLs = [...relatedURLs, ...additionalRelatedURLs];

          if (!allURLs.includes(pathName)) {
            history.push(fullURL);
          }
        };

        const isCompanyIdValid = (companyId = "undefined", companyIdArray: string[]) => {
          if (companyIdArray.includes(companyId)) {
            return true;
          }

          return false;
        };

        const companyRedirectFlow = (companyInfo: ICompany) => {
          switch (companyInfo.status) {
            case CompanyListItemStatus.AddPaymentMethod:
              history.push(`/add-payment/${companyInfo._id}`);
              break;
            case CompanyListItemStatus.KYCPending:
              logger.log("info", "CheckOnboardingFlow KYC Pending");
              // check sub cases
              if (
                // nominee is other than admin
                companyInfo.kycAuthorization?.hasNominated &&
                companyInfo.kycAuthorization.nomineeId !== currentUserUpdatedInfo?._id &&
                ![...commonURLs, "admin-view"].includes(pathName)
              ) {
                logger.log("info", "CheckOnboardingFlow KYC PENDING - showing admin view");
                // show admin view for first-company (will see the awaiting authorisation screen)
                history.push(`/company/${companyInfo._id}/admin-view`);
              } else if (
                // nominee is also admin / has not finished kyc
                companyInfo.kycAuthorization?.hasNominated &&
                companyInfo.kycAuthorization.nomineeId === currentUserUpdatedInfo?._id &&
                userStatus !== UserStatus.DONE
              ) {
                verificationRedirect();
              } else if (
                // nominee is also admin / kyc using greenid is done
                companyInfo.kycAuthorization?.hasNominated &&
                companyInfo.kycAuthorization.nomineeId === currentUserUpdatedInfo?._id &&
                userStatus === UserStatus.DONE
              ) {
                logger.log("info", "CheckOnboardingFlow KYC PENDING - redirect to authorise");
                history.push(`/authorise-mystake-final-step/${companyInfo._id}`);
              } else if (!companyInfo?.kycAuthorization || !companyInfo.kycAuthorization.hasNominated) {
                logger.log("info", "CheckOnboardingFlow KYC PENDING - redirect to select nominee");
                // nominee selection page
                history.push(`/authorise-mystake/${companyInfo._id}`);
              }
              logger.log("info", "CheckOnboardingFlow KYC PENDING - none of the above - break.");
              break;
            case CompanyListItemStatus.SetUpInProgress:
            case CompanyListItemStatus.ReadyForReview:
            case CompanyListItemStatus.Active:
              // This condition is for invited admins, who must run through the verify ID flow
              if (companyInfo.primaryAdminId !== currentUserUpdatedInfo?._id) {
                if (userStatus !== UserStatus.DONE) {
                  verificationRedirect("admin");
                } else if (
                  ![...commonURLs, "authorised-account-created"].includes(pathName) &&
                  !/^company\//.test(pathName)
                ) {
                  history.push(`/company/${companyInfo._id}/admin-view`);
                }
                // This condition is for admins who created the company account, they do not run through verify ID flow
              } else if (
                ![...commonURLs, "authorised-account-created"].includes(pathName) &&
                !/^company\//.test(pathName)
              ) {
                history.push(`/company/${companyInfo._id}/admin-view`);
              }

              break;
            default:
              break;
          }
        };

        const nomineeRedirectFlow = (companyInfo: ICompany) => {
          switch (companyInfo.status) {
            case CompanyListItemStatus.KYCPending:
              history.push(`/nominee/authorise-mystake-final-step/${companyInfo._id}`);
              break;
            case CompanyListItemStatus.SetUpInProgress:
            case CompanyListItemStatus.ReadyForReview:
            case CompanyListItemStatus.Active:
              if (
                ![
                  ...commonURLs,
                  ...verifyIdURLs,
                  "authorised-account-created",
                  "nominee/authorised-account-created",
                ].includes(pathName) &&
                pathName !== "" &&
                !/^$|^company\//.test(pathName) &&
                !(currentUserUpdatedInfo.isInvestor === true && /^investor\/company\//.test(pathName))
              ) {
                history.push(`/company/${nomineeForCompanyIds[0]}/admin-view`);
              }

              break;
            default:
              break;
          }
        };

        // check if current user is a nominee and not admin for the company
        const { isNominee, nomineeForCompanyIds } = await userService.isNominee();
        const totalUserCompanies = Array.from(new Set(currentUserUpdatedInfo?.companyIds)).length || 0;

        if (isNominee) {
          logger.log("info", "CheckOnboardingFlow currentUser - isNominee");

          if (userStatus !== UserStatus.DONE) {
            verificationRedirect("nominee");
            return;
          }

          // Check that user is authorized for the companyIdParam
          if (
            companyIdParam &&
            !isCompanyIdValid(companyIdParam, nomineeForCompanyIds) &&
            !isCompanyIdValid(companyIdParam, currentUserUpdatedInfo.companyIds)
          ) {
            // eslint-disable-next-line consistent-return
            return history.push(PageRoutes.SWITCH_COMPANY);
          }

          // this scenario is for nominees who are only associated with one company - either as a nominee only, or as both a nominee and investor of the same company
          if (totalUserCompanies === 1 && nomineeForCompanyIds.length === 1) {
            const companyInfo = (await companyService.getCompanyInfo(nomineeForCompanyIds[0])).payload;

            nomineeRedirectFlow(companyInfo);
            // this scenario is for nominee users who are associated with a 2 or more different companies either as an investor, admin or nominee
          } else if (totalUserCompanies > 1 && nomineeForCompanyIds.length > 0) {
            if (
              !companyIdParam &&
              ![...commonURLs, ...verifyIdURLs, "authorised-account-created"].includes(pathName) &&
              pathName !== "" &&
              !/^$|^company\//.test(pathName) &&
              !(currentUserUpdatedInfo?.isInvestor && /^investor\/company\//.test(pathName))
            ) {
              history.push(PageRoutes.SWITCH_COMPANY);
            }

            if (companyIdParam) {
              const companyInfo = (await companyService.getCompanyInfo(companyIdParam)).payload;

              if (companyInfo.status === CompanyListItemStatus.KYCPending) {
                if (
                  ![
                    ...commonURLs,
                    ...verifyIdURLs,
                    "authorised-account-created",
                    "nominee/authorised-account-created",
                    `nominee/authorise-mystake-final-step/${companyInfo._id}`,
                  ].includes(pathName) &&
                  pathName !== ""
                ) {
                  history.push("/switch-company");
                }
              } else if (
                ![
                  ...commonURLs,
                  ...verifyIdURLs,
                  "authorised-account-created",
                  "nominee/authorised-account-created",
                ].includes(pathName) &&
                pathName !== "" &&
                !/^$|^company\//.test(pathName) &&
                !/^investor\/company\//.test(pathName)
              ) {
                history.push(`/company/${nomineeForCompanyIds[0]}/admin-view`);
              }
            }
          }
        } else if (
          currentUserUpdatedInfo?.isInvestor ||
          pathName.substring(0, 8) === "investor" ||
          pathName.substring(0, 6) === "wallet"
        ) {
          logger.log("info", pathName);
          logger.log("info", "CheckOnboardingFlow currentUser - isInvestor");

          if (userStatus !== UserStatus.DONE) {
            verificationRedirect(pathName.substring(0, 6) === "wallet" ? "wallet" : "investor");
            return;
          }

          if (!["wallet", "account", "switch-company"].includes(pathName) && !/^investor\/company\//.test(pathName)) {
            if (/^company\/.*\/investor-relations/.test(pathName)) {
              logger.info("pushed to investor portal for investor-relations");
              history.push(`/investor/${pathName}`);
              return;
            }

            if (pathName === "investor/email-verified") {
              return;
            }

            logger.log("info", "CheckOnboardingFlow - Need to change the path");
            logger.log("info", `total companies: ${totalUserCompanies}`);
            if (totalUserCompanies > 1) {
              logger.info("pushed to switch company");
              history.push("/switch-company");
              return;
            }
            logger.log("info", "CheckOnboardingFlow isInvestor --> investor-view");
            const companyId = currentUserUpdatedInfo?.companyIds[0];
            const shareholdings = currentUserUpdatedInfo?.shareholdings || [];
            const { shareholderId } = shareholdings[0];
            history.push(`/investor/company/${companyId}/investor-view/${shareholderId}`);
          }
        } else {
          logger.log("info", "CheckOnboardingFlow currentUser - restOfUs");

          if (!isEmailVerified) {
            history.push("/verify-email");
          } else if (!isPhoneVerified) {
            if (pathName !== "verify-phone") history.push("/email-verified");
          } else if (totalUserCompanies === 1) {
            const companyId = currentUserUpdatedInfo?.companyIds[0];
            const companyInfo = (await companyService.getCompanyInfo(companyId as string)).payload;

            logger.log("info", `CheckOnboardingFlow company status: ${companyInfo.status}`);

            companyRedirectFlow(companyInfo);
          } else if (totalUserCompanies > 1) {
            if (!companyIdParam && ![...commonURLs, ...verifyIdURLs, "authorised-account-created"].includes(pathName)) {
              // eslint-disable-next-line consistent-return
              return history.push(PageRoutes.SWITCH_COMPANY);
            }
            if (companyIdParam && !isCompanyIdValid(companyIdParam, currentUserUpdatedInfo.companyIds)) {
              history.push(PageRoutes.SWITCH_COMPANY);
            } else if (companyIdParam && !pathName.includes("add-company")) {
              const companyInfo = (await companyService.getCompanyInfo(companyIdParam as string)).payload;

              companyRedirectFlow(companyInfo);
            }
          }
        }
        logger.log("info", "CheckOnboardingFlow - exit");
      } catch (error) {
        logger.log(
          "error",
          `An error occurred during execution of the redirectRoute function in the CheckOnboardingFlow component. Here is the error: ${error}`,
        );
      }
    },
    [companyIdParam, getUpdatedUser, history],
  );

  React.useEffect(() => {
    if (currentUser) {
      logger.log("info", "CheckOnboardingFlow currentUser - redirectRoute about to enter");
      redirectRoute(location.pathname);
    }
  }, [currentUser, location.pathname, redirectRoute]);

  return null;
};
