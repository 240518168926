import {
  AnnouncementService,
  AuthService,
  IAuthService,
  UserService,
  VerificationService,
  CompanyService,
  FeatureService,
  ShareClassService,
  PaymentService,
  AddressService,
  FundraiseService,
  WalletService,
  CertificateService,
  LiquidiseService,
} from "@boulevard1/mystake-api-sdk";

const useStub = process.env.USE_STUB === "true" || false;

export const announcementService = AnnouncementService.create(useStub);
export const authService: IAuthService = AuthService.create(useStub);
export const userService = UserService.create(useStub);
export const verificationService = VerificationService.create(useStub);
export const companyService = CompanyService.create(useStub);
export const featureService = FeatureService.create(useStub);
export const shareClassService = ShareClassService.create(useStub);
export const paymentService = PaymentService.create(useStub);
export const addressService = AddressService.create(useStub);
export const fundraiseService = FundraiseService.create();
export const walletService = WalletService.create();
export const liquidiseService = LiquidiseService.create();
export const certificateService = CertificateService.create();
