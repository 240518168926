import { lazy } from "react";
import { IRoute } from "./RouteInterface";
import { PageRoutes } from "../constants/constants";

export const routes: IRoute[] = [
  // create account process routes
  {
    component: lazy(() => import("../components/createAccount/register")),
    path: "/create-account",

    exact: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyEmail/EmailVerified")),
    path: ["/email-verified", "/investor/email-verified"],

    exact: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyEmail/InvalidToken")),
    path: ["/invalid-token", "/investor/invalid-token"],
    exact: true,

    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyEmail/VerifyEmail")),
    path: ["/verify-email/:invalid?", "/investor/verify-email/:invalid?"],

    private: true,
    exact: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyMobile")),
    path: "/verify-phone",

    private: true,
    exact: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/addPaymentMethod")),
    path: "/add-payment/:companyId",

    private: true,
    exact: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/authoriseMystake/AuthoriseMystakeFirstStep")),
    path: "/authorise-mystake/:companyId",

    private: true,
    exact: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyId/VerifyId")),
    exact: true,
    path: ["/verify-id", "/investor/verify-id", "/admin/verify-id", "/wallet/verify-id"],

    private: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyId/GreenId")),
    exact: true,
    path: ["/green-id", "/investor/green-id", "/admin/green-id", "/wallet/green-id"],

    private: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/pages/GreenIdPending")),
    exact: true,
    path: ["/green-id-pending", "/investor/green-id-pending", "/admin/green-id-pending", "/nominee/green-id-pending"],

    private: true,
    container: lazy(() => import("../components/pages")),
  },
  {
    component: lazy(() => import("../components/createAccount/authoriseMystake/AuthoriseMystakeFinalStep")),
    exact: true,
    path: "/authorise-mystake-final-step/:companyId",

    private: true,
    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() =>
      import("../components/createAccount/verifyEmail/InvitationRevoked").then((module) => ({
        default: module.InvitationRevoked,
      })),
    ),
    path: "/invitation-revoked",
    private: true,
    exact: true,
  },
  // director process routes
  {
    component: lazy(() => import("../components/directorFlow/Register")),
    exact: true,
    path: "/nominee/create-account",

    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyEmail/EmailVerified")),
    exact: true,
    path: "/nominee/email-verified",

    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyEmail/InvalidToken")),
    exact: true,
    path: "/nominee/invalid-token",

    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyEmail/VerifyEmail")),
    exact: true,
    path: ["/nominee/verify-email", "/nominee/verify-email/:invalid?"],

    private: true,
    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyMobile")),
    exact: true,
    path: "/nominee/verify-phone",

    private: true,
    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyId/VerifyId")),
    exact: true,
    path: "/nominee/verify-id",

    private: true,
    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/createAccount/verifyId/GreenId")),
    exact: true,
    path: "/nominee/green-id",

    private: true,
    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/directorFlow/AuthoriseMystakeFinalStep")),
    exact: true,
    path: "/nominee/authorise-mystake-final-step/:companyId",

    private: true,
    container: lazy(() => import("../components/directorFlow")),
  },
  {
    component: lazy(() => import("../components/pages/NomineeAccountCreated")),
    path: "/nominee-account-created",

    container: lazy(() => import("../components/pages")),
  },
  {
    component: lazy(() => import("../components/pages/AuthorisedAccountCreated")),
    path: [
      "/authorised-account-created",
      "/nominee/authorised-account-created",
      "/admin/authorised-account-created",
      "/investor/authorised-account-created",
    ],

    container: lazy(() => import("../components/pages")),
  },
  {
    component: lazy(() => import("../components/pages/AuthorisedAccountPending")),
    path: ["/authorised-account-pending", "/nominee/authorised-account-pending"],

    container: lazy(() => import("../components/pages")),
  },
  {
    component: lazy(() => import("../components/pages/LinkExpired")),
    path: "/link-expired",

    container: lazy(() => import("../components/pages")),
  },
  {
    component: lazy(() => import("../components/pages/PasswordResetLinkExpired")),
    path: "/password-reset-link-expired",

    container: lazy(() => import("../components/pages")),
  },
  {
    component: lazy(() => import("../components/pages/InactiveCompany")),
    path: "/inactive-company",
    exact: true,
    container: lazy(() => import("../components/pages")),
  },
  // admin routes
  {
    component: lazy(() => import("../components/createAdminAccount/Register")),
    path: "/admin/create-account",

    exact: true,
    container: lazy(() => import("../components/createAccount")),
  },
  // Signin routes
  {
    component: lazy(() => import("../components/signInFlow/signin")),
    path: "/signin",

    container: lazy(() => import("../components/signInFlow")),
  },
  {
    component: lazy(() => import("../components/signInFlow/forgotPassword")),
    path: "/forgot-password",

    container: lazy(() => import("../components/signInFlow")),
  },
  {
    component: lazy(() => import("../components/signInFlow/resetPassword")),
    path: "/reset-password/:token",

    container: lazy(() => import("../components/signInFlow")),
  },
  {
    component: lazy(() => import("../components/signInFlow/resetPassword/PasswordUpdated")),
    path: "/password-updated",
    exact: true,
    container: lazy(() => import("../components/signInFlow")),
  },

  // Company Routes
  {
    component: lazy(() => import("../components/companies/companyList")),
    path: PageRoutes.COMPANIES_LIST,
    private: true,
    exact: true,
  },
  {
    component: lazy(() => import("../components/companies/profileSetting")),
    path: "/account",
    private: true,
  },
  {
    component: lazy(() => import("../components/companies/switchCompany")),
    path: "/switch-company",
    private: true,
  },
  {
    component: lazy(() => import("../components/companies/addCompany")),
    path: ["/add-company", "/add-company/:companyId"],
    private: true,
    // container: lazy(() => import("../components/companies/addCompany")),
  },
  {
    component: lazy(() => import("../components/companies/admin-view")),
    path: ["/company/:companyId/admin-view", "/"],
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/investorRelations/announcements")),
    path: "/company/:companyId/investor-relations/announcements",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/investorRelations/announcements/Announcement")),
    path: [
      "/company/:companyId/investor-relations/announcement/:announcementId",
      "/investor/company/:companyId/investor-relations/investor/:shareholderId/announcement/:announcementId",
    ],
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/investorRelations/contacts")),
    path: "/company/:companyId/investor-relations/contacts",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/investorRelations/invitations")),
    path: "/company/:companyId/investor-relations/invitations",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/investors")),
    path: "/company/:companyId/investors",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation")),
    path: "/company/:companyId/company-information",
    exact: true,
    private: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/optionsPlans/createOptionsPlan")),
    path: "/company/:companyId/company-information/options/grant",
    exact: true,
    private: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/shares")),
    path: "/company/:companyId/shares-register",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/options")),
    path: "/company/:companyId/options-register",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/notes")),
    path: "/company/:companyId/notes-register",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/investorHoldingDetails")),
    path: "/company/:companyId/register/investor-holdings/:shareholderId",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/register/investorHoldingDetails/edit").then((module) => ({
        default: module.EditOwnerDetailsPage,
      })),
    ),
    path: [
      "/company/:companyId/register/investor-holdings/:shareholderId/edit",
      "/investor/company/:companyId/register/investor-holdings/:shareholderId/edit",
      "/investor/company/:companyId/register/investor-holdings/:shareholderId/edit/trading",
    ],
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/investorHoldingDetails/options")),
    path: [
      "/company/:companyId/register/investor-holdings/:shareholderId/options/:optionGrantId/details",
      "/investor/company/:companyId/investor-view/:shareholderId/options/:optionGrantId/details",
      "/investor/company/:companyId/investor-view/:shareholderId/switch-holding/options/:optionGrantId/details",
    ],
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/investorHoldingDetails/options/vest")),
    path: [
      "/company/:companyId/register/investor-holdings/:shareholderId/options/:optionGrantId/vest",
      "/investor/company/:companyId/investor-view/:shareholderId/options/:optionGrantId",
      "/investor/company/:companyId/investor-view/:shareholderId/switch-holding/options/:optionGrantId",
    ],
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/investorHoldingDetails/options/exercise")),
    path: ["/company/:companyId/register/investor-holdings/:shareholderId/options/:optionGrantId/exercise"],
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/investorHoldingDetails/notes")),
    path: [
      "/company/:companyId/register/investor-holdings/:shareholderId/notes/:notetransactionId",
      "/investor/company/:companyId/investor-view/:shareholderId/notes/:notetransactionId",
      "/investor/company/:companyId/investor-view/:shareholderId/switch-holding/notes/:notetransactionId",
    ],
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/optionsPools")),
    path: "/company/:companyId/company-information/options/pool-list",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/optionsPools/CreateOptionsPool")),
    path: "/company/:companyId/company-information/options/create-pools",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/officeholders")),
    path: "/company/:companyId/company-information/officeholders/list",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/companyInformation/fundraisingBankAccount").then((module) => ({
        default: module.Fundraise,
      })),
    ),
    path: "/company/:companyId/company-information/fundraise",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/notes")),
    path: "/company/:companyId/company-information/notes-summary",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/notes/create")),
    path: "/company/:companyId/company-information/create-notes",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/notes/issue")),
    path: "/company/:companyId/company-information/issue-notes",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/notes/certificate")),
    path: "/company/:companyId/company-information/notes-certificate",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/officeholders/appoint")),
    path: "/company/:companyId/company-information/officeholders/appoint",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/officeholders/edit")),
    path: "/company/:companyId/company-information/officeholders/:officeholderId/edit",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/officeholders/remove")),
    path: "/company/:companyId/company-information/officeholders/remove",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/shares/sharePrice")),
    path: "/company/:companyId/company-information/share-price",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/shares/shareCertificate")),
    path: "/company/:companyId/company-information/share-certificate",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/companyInformation/sharePriceHistory")),
    path: "/company/:companyId/company-information/share-price/history",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/shares/issueShares")),
    path: "/company/:companyId/shares-register/issue-share",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/register/shares/transferShares")),
    path: "/company/:companyId/shares-register/transfer-shares",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/settings")),
    path: "/company/:companyId/settings",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/settings/ViewAdministrators")),
    path: "/company/:companyId/settings/administrators",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/documents")),
    path: "/company/:companyId/documents",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/help")),
    path: "/help",
    private: true,
    exact: true,

    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/activity")),
    path: "/company/:companyId/activity",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/fundraise")),
    path: "/company/:companyId/fundraises",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/fundraise/create").then((module) => ({
        default: module.CreateFundraisingRound,
      })),
    ),
    path: "/company/:companyId/create-fundraise",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/fundraise/issue")),
    path: "/company/:companyId/fundraise-offer",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/companies/fundraise/fundraiseDetails")),
    path: "/company/:companyId/fundraises/:fundraiseId/details",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  // Investor Routes
  {
    component: lazy(() => import("../components/investor/Register")),
    exact: true,
    path: "/investor/create-account",

    container: lazy(() => import("../components/createAccount")),
  },
  {
    component: lazy(() => import("../components/companies/register/investor-view")),
    path: [
      "/investor/company/:companyId/investor-view/:shareholderId",
      "/investor/company/:companyId/investor-view/:shareholderId/switch-holding",
      "/",
      "/investor/company/:companyId/investor-view/:shareholderId/payment-details/:fundraiseOfferId",
    ],
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/register/investor-view/acceptAndPay").then((module) => ({
        default: module.AcceptAndPay,
      })),
    ),
    path: "/investor/company/:companyId/investor-view/:shareholderId/accept-and-pay/:fundraiseOfferId",
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/register/investor-view/switchHolding").then((module) => ({
        default: module.SwitchHolding,
      })),
    ),
    path: ["/investor/company/:companyId/switch-holding", "/investor/company/:companyId/switch-holding/trading"],
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() => import("../components/investor/Announcements")),
    path: [
      "/investor/company/:companyId/investor-relations/investor/:shareholderId/announcement",
      "/investor/company/:companyId/investor-relations/announcement",
    ],
    private: true,
    exact: true,
    container: lazy(() => import("../components/companies")),
  },
  // user routes
  {
    component: lazy(() =>
      import("../components/users/confirmEmailUpdate").then((module) => ({
        default: module.ConfirmEmailUpdate,
      })),
    ),
    path: "/user/confirm-email-update",
    exact: true,
    container: lazy(() => import("../components/pages")),
  },
  {
    component: lazy(() =>
      import("../components/users/cancelEmailUpdate").then((module) => ({
        default: module.CancelEmailUpdate,
      })),
    ),
    path: "/user/cancel-email-update",
    exact: true,
    container: lazy(() => import("../components/pages")),
  },
  // Liquidise Routes
  {
    component: lazy(() =>
      import("../components/companies/trading/BuyShares").then((module) => ({
        default: module.BuyShares,
      })),
    ),
    path: "/investor/company/:companyId/trading/:shareholderId/trading-window/buy-shares",
    private: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/trading/investor-trading/TradingWindow/SellShares").then((module) => ({
        default: module.SellShares,
      })),
    ),
    path: "/investor/company/:companyId/trading/:shareholderId/trading-window/sell-shares",
    private: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/trading").then((module) => ({
        default: module.Trading,
      })),
    ),
    path: [
      "/company/:companyId/trading/history",
      "/company/:companyId/trading/manage",
      "/investor/company/:companyId/trading/:shareholderId",
      "/investor/company/:companyId/trading/:shareholderId/switch-holding",
    ],
    private: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/companies/trading/TradingWindowDetails").then((module) => ({
        default: module.TradingWindowDetails,
      })),
    ),
    path: "/company/:companyId/trading/trading-window",
    private: true,
    container: lazy(() => import("../components/companies")),
  },
  {
    component: lazy(() =>
      import("../components/investor/wallet").then((module) => ({
        default: module.Wallet,
      })),
    ),
    path: ["/investor/company/:companyId/wallet", "/investor/company/:companyId/kyc-done"],
    private: true,
  },
];
