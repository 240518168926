import { styled, withStyles } from "@material-ui/core";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import React from "react";
import { useTheme } from "../theme";
import { color } from "../theme/Design";

export const MuiCheckboxV2 = styled(({ ...props }: CheckboxProps) => (
  <CheckboxWithBlackCheck disabled={props.readOnly || props.disabled} color="default" {...props} />
))({
  padding: 8,
  marginTop: -2,
});

export const MuiCheckbox = styled(({ ...props }: CheckboxProps) => (
  <Checkbox
    color="default"
    icon={props.readOnly || props.disabled ? <CheckboxInActiveDisabledIcon /> : <CheckboxInActiveIcon />}
    checkedIcon={props.readOnly || props.disabled ? <CheckboxActiveDisabledIcon /> : <CheckboxActiveIcon />}
    {...props}
  />
))({
  marginLeft: -9,
  marginRight: 8,
});

export const CheckboxActiveIcon = styled(({ ...other }) => (
  <svg {...other} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill={color.primary} />
    <rect width="24" height="24" rx="4" fill="black" fillOpacity="0.05" />
    <path d="M18.3927 5.60657L9.2203 18.3923L5.60693 14.779" stroke={color.black800} strokeWidth="2" />
  </svg>
))({});
export const CheckboxInActiveIcon = styled(({ ...other }) => {
  const { themeType } = useTheme()!;
  return (
    <svg {...other} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        stroke={themeType === "light" ? color.primaryInputBorder : color.primaryLight}
      />
    </svg>
  );
})({});

const CheckboxInActiveDisabledIcon = styled(({ ...other }) => (
  <svg {...other} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="3.5"
      fill={color.lightGray}
      fillOpacity="0.7"
      stroke={color.primaryLight}
    />
  </svg>
))({});

const CheckboxActiveDisabledIcon = styled(({ ...other }) => (
  <svg {...other} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="3.5"
      fill={color.lightGray}
      fillOpacity="0.7"
      stroke={color.primaryLight}
    />
    <path d="M18.3929 5.60657L9.22054 18.3923L5.60718 14.779" stroke={color.black800} strokeWidth="2" />
  </svg>
))({});

const CheckboxWithBlackCheck = withStyles({
  root: {
    color: color.gray,
    "&.Mui-disabled": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: color.lightGray,
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: 21.34,
    },
    "&.MuiButtonBase-root": {
      margin: 0,
      padding: 0,
      height: 22,
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 14,
        width: 14,
        position: "absolute",
        backgroundColor: "black",
        zIndex: -1,
      },
    },
  },
  checked: {},
})(Checkbox);
