/*

## Design System Helper

This helper file uses values from our Design System in Figma,
"Boulevard UI v2.2", which were accurate as of March 2023.

## Legacy

The "color" and "dashboardColor" exports here are now deprecated and should
not be used for new code changes. They are only retained here for backwards
compatibility.

## Usage

import { Colour, Font } from "./components/theme/Design";

let exampleStyle = {
  color: Colour.blue,
  fontFamily: Font.name,
  fontWeight: Font.weight.medium,
}

*/

// prettier-ignore
export const Colour = {
  white:        "#FFFFFF", // White
  peachLight:   "#FFF5EB", // Light Peach
  pinkLight:    "#FDE5E5",  // Light Pink
  peach:        "#FF9A81", // BLVD Peach
  red:          "#F14F4F", // Red (Critical)
  orangeLight:  "#FFBB70", // Light Orange
  orange:       "#FF8600", // Orange (Caution)
  mintlight:    "#DAFDE5", // BLVD Light Mint
  mint:         "#A2FFC8", // BLVD Mint
  mint5:        "#9AF2BE", // BLVD Mint + 5% shade
  mint20:       "#82CCA0", // BLVD Mint + 20% shade
  green:        "#2AB16E", // Green (Positive)
  fog:          "#C9DBF1", // BLVD Fog
  blue:         "#323B70", // BLVD Blue
  blueMedium:   "#343D60", // BLVD Medium Blue
  blueDark:     "#192243", // BLVD Dark Blue
  greylight:    "#F1F3F6", // Light Grey
  greyLighter:  "#D0D9E2", // Lighter Grey
  greyLightInfo:"#D6D6D6", // Light Grey (info)
  greymid:      "#C4C4C4", // Mid Grey
  grey:         "#7C7E81", // Grey
  slate:        "#343740", // BLVD Slate
  skeletonGrey: "#ededed", // Skeleton Grey
};

export const FontWeight = {
  medium: "500",
  semibold: "600",
  bold: "700",
};

export const FontSize = {
  small3: "8px",
  small2: "10px",
  small: "12px",
  regular: "14px",
  large: "16px",
  large1: "18px",
  large2: "22px",
  large3: "26px",
  large4: "30px",
  large5: "34px",
};

export const Font = {
  name: "Montserrat",
  weight: {
    ...FontWeight,
  },
  heading: {
    one: {
      size: FontSize.large5,
      weight: FontWeight.bold,
    },
    two: {
      size: FontSize.large4,
      weight: FontWeight.bold,
    },
    three: {
      size: FontSize.large3,
      weight: FontWeight.bold,
    },
    four: {
      size: FontSize.large2,
      weight: FontWeight.bold,
    },
    five: {
      size: FontSize.large1,
      weight: FontWeight.semibold,
    },
    six: {
      size: FontSize.large,
      weight: FontWeight.semibold,
    },
  },
  paragraph: {
    large: {
      medium: {
        size: FontSize.regular,
        weight: FontWeight.medium,
      },
      bold: {
        size: FontSize.regular,
        weight: FontWeight.semibold,
      },
    },
    regular: {
      medium: {
        size: FontSize.small,
        weight: FontWeight.medium,
      },
      bold: {
        size: FontSize.small,
        weight: FontWeight.semibold,
      },
    },
    small: {
      medium: {
        size: FontSize.small2,
        weight: FontWeight.medium,
      },
      bold: {
        size: FontSize.small2,
        weight: FontWeight.bold,
      },
    },
    fine: {
      medium: {
        size: FontSize.small3,
        weight: FontWeight.medium,
      },
      bold: {
        size: FontSize.small3,
        weight: FontWeight.bold,
      },
    },
  },
};

export const Form = {
  field: {
    margin: {
      top: "5px",
      bottom: "5px",
    },
    checkbox: {
      width: "16px",
      height: "16px",
      margin: {
        left: "8px",
        right: "8px",
      },
    },
    radio: {
      width: "16px",
      height: "16px",
      margin: {
        left: "8px",
        right: "8px",
      },
    },
  },
  link: {
    icon: {
      margin: {
        right: "8px",
      },
    },
  },
};

export const Button = {
  colour: {
    primary: {
      active: {
        background: Colour.mint,
        font: Colour.slate,
        border: "transparent",
      },
      hover: {
        background: Colour.mintlight,
        font: Colour.slate,
        border: "transparent",
      },
      disabled: {
        background: Colour.greylight,
        font: Colour.white,
        border: "transparent",
      },
    },
    secondary: {
      active: {
        background: Colour.white,
        font: Colour.slate,
        border: Colour.greylight,
      },
      hover: {
        background: Colour.mintlight,
        font: Colour.slate,
        border: "transparent",
      },
      disabled: {
        background: Colour.white,
        font: Colour.greylight,
        border: Colour.greylight,
      },
    },
    tertiary: {
      active: {
        background: Colour.white,
        font: Colour.slate,
        border: "transparent",
      },
      hover: {
        background: Colour.mintlight,
        font: Colour.slate,
        border: "transparent",
      },
      disabled: {
        background: Colour.white,
        font: Colour.greylight,
        border: "transparent",
      },
    },
  },
  font: {
    size: Font.paragraph.large.bold.size,
    weight: Font.paragraph.large.bold.weight,
  },
  padding: {
    left: "15px",
    right: "15px",
  },
  small: {
    width: "110px",
    height: "36px",
  },
  large: {
    width: "140px",
    height: "48px",
  },
  margin: {
    horizontal: "15px",
  },
};

// LEGACY - Color
/**
 * @deprecated Please use the "Colour" export from "components/theme/Design" instead
 */
export const color = {
  black: "#000000",
  black800: "#343740",
  primary: "#A2FFC8",
  primaryLight: "#DAFDE5", // input border color
  primaryDark: "#009C9C",
  primaryInputBorder: "#82CCA0",
  secondary: "#7375FC",
  white: "#fff",
  gray: "#7C7E81",
  gray500: "#C4C4C4", // card border
  skyBlue: "#EFEFFF",
  info: "#D6D6D6",
  error: "#F14F4F",
  progressBarError: "#FF554C",
  darkBlue: "#192243", // dark theme
  blue100: "#F6F8FB",
  blue900: "#1F313D",
  blue800: "#343D60", // input box
  orange: "#F69E1E",
  darkBorder: "#E9F0FC",
  green: "#9AF2BE",
  primaryGradient: "linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #A2FFC8",
  primaryGradientDark: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #A2FFC8",
  greenGradient: "linear-gradient(97.49deg, #A3FFC8 2.19%, #71FFE5 104.79%)",
  disabledColor: "#5F6995", // button background and button border
  lightGray: "#D0D9E2",
};

// LEGACY - Dashboard Color
/**
 * @deprecated Please use the "Colour" export from "components/theme/Design" instead
 */
export const dashboardColor = {
  bg: "#fff",
  defaultBorder: "1px solid #D6D6D6",
  defaultBorderColor: "#D6D6D6",
  defaultRadius: 6,
};
