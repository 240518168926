import React from "react";
import { BrowserRouter } from "react-router-dom";
import { styled } from "@material-ui/core";
import useIntercom from "@reclaim-ai/react-intercom-hook";
import { Snackbar } from "./components/uiComponents";
import { ThemeProviderContainer as Theme } from "./components/theme";
import { CompanyContextProvider } from "./components/companies/useCompanyContext";
import { AuthProvider } from "./auth/AuthProvider";
import { CheckOnboardingFlow } from "./components/CheckOnboardingFlow";
import { Router } from "./routes/Router";
import { routes } from "./routes/RoutesConfig";

export const App: React.FC = () => {
  useIntercom({ app_id: window.config.INTERCOM_APP_ID });
  return (
    <AuthProvider>
      <Theme>
        <Snackbar maxSnack={6}>
          <div className="App">
            <BrowserRouter>
              <CompanyContextProvider>
                <Main>
                  <CheckOnboardingFlow />
                  <Router routes={routes} />
                </Main>
              </CompanyContextProvider>
            </BrowserRouter>
          </div>
        </Snackbar>
      </Theme>
    </AuthProvider>
  );
};

const Main = styled("main")({
  minHeight: "100vh",
});
